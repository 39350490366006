<template>
  <div
    class="state"
    v-if="loading"
  >
    <div class="statistics wrap_box">
      <div class="header">
        设备总体运行情况
      </div>
      <echart
        :config="echartConfig"
        :option="echartOption"
        v-if="echartOption"
      />
      <div class="resultGrid">
        <div
          class="box"
          v-for="item in totalData.stateData"
          :key="item.name"
        >
          <div class="name">
            {{ item.name }}
          </div>
          <div class="value">
            {{ item.value }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="list wrap_box"
      v-for="type in dataListKeys"
      :key="type"
    >
      <div class="header">
        {{ dataList[type].title }}
      </div>
      <el-table
        :data="dataList[type].data"
        :class="['tableList', type]"
      >
        <el-table-column
          type="index"
          width="80"
        />
        <el-table-column
          v-for="(item, i) in tableColumn"
          :label="item.label"
          :prop="item.prop"
          :minWidth="item.minWidth"
          :showOverflowTooltip="item.overflow"
          :key="type + i"
        />
      </el-table>
    </div>
  </div>
</template>
<script>
import echart from '@/libs/components/echart/echart'
import json from './json'
export default {
  name: 'StateAA',
  components: {
    echart
  },
  props: {
    totalData: {
      type: Object,
      default: () => {
        return { stateChart: [], stateData: [] }
      }
    }
  },
  data () {
    return {
      loading: false,
      echartOption: '',
      echartConfig: {
        id: 'state',
        setStyle: { width: '30rem', height: '30rem', margin: '0 auto' }
      },
      tableColumn: [
        { label: '姓名', prop: 'realname', minWidth: '80%', overflow: true },
        { label: '开方量', prop: 'recipeTotal', minWidth: '20%', overflow: true }
      ],
      dataList: {},
      dataListMonth: []
    }
  },
  computed: {
    dataListKeys () {
      return Object.keys(this.dataList)
    }
  },
  watch: {
    totalData: {
      handler () {
        this.buildEchartOption()
      },
      deep: true
    }
  },
  mounted () {
    this.buildEchartOption()
  },
  created () {
    /* this.dataList = json.stateData*/
    this.getDataList()
    this.loading = true
  },
  methods: {
    buildEchartOption () {
      const px = window.innerWidth / 120 / 16
      let echartData = this.totalData.equipmentChart
      this.echartOption = {
        legend: {
          bottom: 'bottom',
          textStyle: {
            color: '#fff',
            fontSize: 16 * px
          }
        },
        label: {
          rich: {
            a: {
              color: '#fff',
              fontSize: 18 * px,
              lineHeight: 24 * px,
              align: 'center'
            }
          },
          formatter: '{a|{b}\n{d}%}'
        },
        color: ['#0263FF', '#FF7723', '#8E30FF'],
        series: [
          {
            name: '设备总体运行情况',
            type: 'pie',
            radius: '50%',
            avoidLabelOverlap: false,
            data: echartData
          }
        ]
      }
    },
    async getDataList () {
      if (this.$route.query.demo !== 'Y') {
        let userInfo = JSON.parse(this.$root.libs.data.getStorage('userInfo'))
        let weekData = await this.$root.libs.request(
          this.$root.libs.api.ssyypt.training_recipe_running_web
            .queryStudioDoctorRecipeTotalNumInDays, { daysIn: '7', limit: 12 }, { headers: { jwt: userInfo.jwt } }
        )
        weekData = weekData.data
        let monthData = await this.$root.libs.request(
          this.$root.libs.api.ssyypt.training_recipe_running_web
            .queryStudioDoctorRecipeTotalNumInDays, { daysIn: '30', limit: 12 }, { headers: { jwt: userInfo.jwt } }
        )
        monthData = monthData.data
        this.dataList = {
          week: {
            title: '近7天医生治疗数量',
            data: weekData
          },
          month: {
            title: '近30天医生治疗数量',
            data: monthData
          }
        }
      } else this.dataList = json.stateData
    }
  }
}
</script>
<style lang="scss" scoped>
.state {
  display: flex;
  flex: 1;
  width: 100%;
  display: flex;
  .header {
    padding-left: var(--space-normal);
    margin: 1rem;
    font-size: var(--font-h2);
    border-left: 4px solid #36c1fe;
  }
  .statistics {
    flex: 1;
    .resultGrid {
      width: 25rem;
      margin: 2.5rem auto;
      display: grid;
      grid-template-columns: repeat(2, 50%);
      column-gap: 1px;
      row-gap: 1px;
      font-size: var(--font-h2);
      font-weight: bold;
      text-align: center;
      color: #fff;
      .box {
        padding-top: 1rem;
        .value {
          font-size: 2em;
          color: #fff;
        }
      }
    }
  }
  .list {
    margin-left: 2rem;
    flex: 1;
    .week {
      color: #79efe5;
    }
    .month {
      color: #c5b8fd;
    }
    .tableList {
      font-size: var(--font-h3);
    }
  }
}
</style>
<style scoped>
.list >>> .el-table,
.list >>> .el-table::before,
.list >>> .el-table tr,
.list >>> .el-table th,
.list >>> .el-table--enable-row-hover .el-table__body tr:hover > td {
  background: none;
}
.list >>> .el-table td,
.list >>> .el-table th,
.list >>> .el-table th.is-leaf {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-width: 0 0 1px 0;
}
.list >>> .el-table .cell {
  line-height: 2;
}
.list >>> .el-table thead {
  color: inherit;
}
.list >>> .el-table tr {
  height: 4rem;
}
</style>
